<template>
  <default-layout>
    <SupportTicketList
      :country-id="user.country_id"
      :display-user-tickets="true"
    />
  </default-layout>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import SupportTicketList from '@/components/admin/support/SupportTicketList';

export default {
  components: {
    SupportTicketList,
  },
  computed: {
    ...mapState('user', ['user']),
  },
  mounted() {
    this.setHasNewSupportMessages(false);
  },
  methods: {
    ...mapMutations('support', ['setHasNewSupportMessages']),
  },
};
</script>
